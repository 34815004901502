import { default as _91_46_46_46slug_93i1qGdWB5nKMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93yd52EENzkLMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexrTlmjUw9o5Meta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/blogg/index.vue?macro=true";
import { default as indexeBBoFyRN1vMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/index.vue?macro=true";
import { default as paymentLpg8EdKAqwMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/payment.vue?macro=true";
import { default as indexV9NhDoG0wlMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/thank-you/index.vue?macro=true";
import { default as indexerqG9GJlNdMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/collections/index.vue?macro=true";
import { default as _91slug_931ox0qAdm4NMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/faq/[slug].vue?macro=true";
import { default as index8KPjBYBSjbMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexZYxTZxcoUMMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/index.vue?macro=true";
import { default as indexXGg3oAUXcYMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/login/index.vue?macro=true";
import { default as _91id_93o2nxrVUaFVMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93tRQ5Ood0EOMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/shop/[...slug].vue?macro=true";
import { default as indexEIc6Kv0qdmMeta } from "/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexeBBoFyRN1vMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: paymentLpg8EdKAqwMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: indexV9NhDoG0wlMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexEIc6Kv0qdmMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241203055436Z/pages/track/index.vue")
  }
]