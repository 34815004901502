import revive_payload_client_4sVQNw7RlN from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/projects/hillceramic.se/releases/20241203055436Z/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/projects/hillceramic.se/releases/20241203055436Z/.nuxt/sentry-client-config.mjs";
import plugin_ghbUAjaD3n from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/var/www/projects/hillceramic.se/releases/20241203055436Z/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import global_properties_qyelYHSMTl from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/global-properties.js";
import v_copy_o6m578V0Vr from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/v-copy.js";
import klaviyo_dv7qcTHmBz from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/klaviyo.js";
import canonical_0a83yNMgHz from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/canonical.js";
import error_handler_kEP5FliEXj from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/error-handler.ts";
import freshchat_UewPqR8WF3 from "/var/www/projects/hillceramic.se/releases/20241203055436Z/plugins/freshchat.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  global_properties_qyelYHSMTl,
  v_copy_o6m578V0Vr,
  klaviyo_dv7qcTHmBz,
  canonical_0a83yNMgHz,
  error_handler_kEP5FliEXj,
  freshchat_UewPqR8WF3
]